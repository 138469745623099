import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbsComponent } from "./breadcrumbs.jsx";
import * as API from "../../helpers/api";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import {
	AppShell,
	Burger,
	Image,
	Flex,
	Container,
	Anchor,
	Box,
	Group,
	useMantineTheme,
	useMantineColorScheme,
	HoverCard,
	Button,
	Text,
	Center
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Sidebar from "./sidebar";
import Notifications from "./notifications";
import Loading from "../modules/loading";
import { useLocation } from "react-router-dom";
import { Translations } from "../../helpers/translator.js";
import { useSocket } from "../../helpers/socket";
import { useLang } from "../../helpers/language";
import { useActivePlatform } from "../../helpers/activePlatform.jsx";
import { T } from "../../helpers/translator";
import ActivityTracker from "./activityTracker.jsx";

let firstTimeLoadedSwitch = false;

export default function Main({ component: Component }) {
	let [isBusy, setIsBusy] = useState(true);
	const [opened, { toggle }] = useDisclosure();
	const location = useLocation();
	const { user, setUser } = useUser();
	const { connectedUsers = [], socket, setNewNotification } = useSocket();
	const theme = useMantineTheme();
	const { colorScheme } = useMantineColorScheme();
	const { lang } = useLang();
	const { activePlatform, setActivePlatform } = useActivePlatform();

	const [notificationOpened = opened, OpenClose] = useDisclosure(false);

	const mainItems = config.menu.map((item, index) => (
		<Anchor
			href={item.link}
			key={index}
			style={{
				display: "block",
				margin: "0 10px",
				height: index === activePlatform ? 44 : 44,
				width: index === activePlatform ? 44 : 44,
				color: colorScheme === "dark" ? theme.colors.gray[0] : theme.colors.gray[9],
				fontWeight: 500,
				fontSize: theme.fontSizes.sm,
				textDecoration: "none",
				position: "relative"
			}}
			data-active={index === activePlatform || undefined}
			onClick={(event) => {
				event.preventDefault();
				setActivePlatform(index);
			}}
		>
			<HoverCard shadow="md">
				<HoverCard.Target>
					<Image
						src={`/assets/${item.icon}`}
						alt="navigation-icon"
						style={{
							borderRadius: "50%",
							// border: index === activePlatform ? "2px solid rgba(0, 123, 255, 0.8)" : "none",
							width: 44,
							height: 44,
							transition: "border 0.25s ease-in-out",
							filter: index === activePlatform ? "grayscale(0)" : "grayscale(1)"
						}}
					/>
				</HoverCard.Target>
				<HoverCard.Dropdown>
					<Text size="sm">{item.title?.[lang]}</Text>
				</HoverCard.Dropdown>
			</HoverCard>
		</Anchor>
	));

	useEffect(() => {
		(async () => {
			let result = {};
			// update user history on server;

			// detect if loaded first time
			if (!firstTimeLoadedSwitch) {
				firstTimeLoadedSwitch = true;
				result = await API.update(config.api.user, user._id, {
					...user,
					history: [
						{
							date: new Date(),
							action: {
								method: "entered",
								name: Translations.entered,
								route: {
									pathname: "/",
									search: "/"
								}
							}
						},
						...user.history.slice(0, 20)
					]
				});
			} else {
				result = await API.update(config.api.user, user._id, {
					...user,
					history: [
						{
							date: new Date(),
							action: {
								method: "view",
								name: Translations.view,
								route: {
									pathname: location.pathname.replace(/\/$/, "").replace(/^\//, ""),
									search: location.search.replace(/^\?/, "")
								}
							}
						},
						...user.history.slice(0, 20)
					]
				});
			}
			setUser({
				...user,
				history: result.history ? result.history : user.history
			});
		})();
		// eslint-disable-next-line
	}, [window.location.pathname]);

	useEffect(() => {
		if (window.location.pathname.includes("chat")) return;

		// if socket.event already exists, remove it
		if (socket?.listeners("newMessage").length > 0) {
			socket.off("newMessage");
		}

		socket?.on("newMessage", async (newMessage) => {
			if (window.location.pathname.includes(`chat/${newMessage.chatId}`)) return;
			if (window.location.pathname.includes(`chat?`)) return;
			if (newMessage.from._id !== user?._id) {
				// just show notification
				let partner = await API.get({
					route: config.api.user,
					query: {
						_id: newMessage.from._id
					},
					uploadType: true,
					limit: 1,
					populate: ["department", "position", "role"]
				});
				let messageBody = newMessage?.body
					? newMessage?.body.length > 30
						? newMessage?.body.slice(0, 30)
						: newMessage?.body
					: "";
				setNewNotification({
					ID: new Date().getTime(),
					date: new Date().toISOString(),
					name: partner[0].name || {
						en: T("nothingFound", lang),
						ru: T("nothingFound", lang),
						hy: T("nothingFound", lang)
					},
					desc: {
						en: messageBody,
						ru: messageBody,
						hy: messageBody
					},
					importance: "medium",
					route: `${config.api.chat}/${partner?._id}`,
					createdByUser: partner[0],
					targetUsers: [
						{
							user: user?._id,
							read: false
						}
					]
				});
			}
		});
		// socket?.on("newMessage", async (newMessage) => {
		// 	let localNewMessage = { ...newMessage };
		// 	if (localNewMessage.from._id === user?._id) {
		// 		console.log("🚀 ~ THIS IS SENDER");
		// 	} else {
		// 		console.log("🚀 ~ THIS IS RECIEVER");
		// 		if (window.location.pathname.includes(`chat/${localNewMessage.chatId}`)) return;
		// 		try {
		// 			let partner = await API.get({
		// 				route: config.api.user,
		// 				query: {
		// 					_id: localNewMessage.from._id
		// 				},
		// 				uploadType: true,
		// 				limit: 1,
		// 				populate: ["department", "position", "role"]
		// 			});
		// 			let messageBody = localNewMessage?.body
		// 				? localNewMessage?.body.length > 30
		// 					? localNewMessage?.body.slice(0, 30)
		// 					: localNewMessage?.body
		// 				: "";
		// 			setNewNotification({
		// 				ID: new Date().getTime(),
		// 				date: new Date().toISOString(),
		// 				name: partner[0].name || {
		// 					en: T("nothingFound", lang),
		// 					ru: T("nothingFound", lang),
		// 					hy: T("nothingFound", lang)
		// 				},
		// 				desc: {
		// 					en: messageBody,
		// 					ru: messageBody,
		// 					hy: messageBody
		// 				},
		// 				importance: "medium",
		// 				route: `${config.api.chat}/${partner?._id}`,
		// 				createdByUser: partner[0],
		// 				targetUsers: [
		// 					{
		// 						user: user?._id,
		// 						read: false
		// 					}
		// 				]
		// 			});
		// 		} catch (error) {
		// 			console.log("🚀 ~ error:", error);
		// 		}
		// 	}
		// });

		return () => {
			socket?.off("newMessage", (data) => {});
		};
		// eslint-disable-next-line
	}, [socket, window.location.href]);

	useEffect(() => {
		// Variable to track the last activity time
		let lastActivity = Date.now();
		let userStatus = "online"; // Track the current user status ("online" or "away")

		// Function to reset activity timer
		const resetActivityTimer = () => {
			lastActivity = Date.now();
		};

		// Add event listeners once
		window.addEventListener("mousemove", resetActivityTimer);
		window.addEventListener("keypress", resetActivityTimer);

		// Interval to check for user status changes
		const checkConnection = setInterval(() => {
			const timeSinceLastActivity = Date.now() - lastActivity;

			if (timeSinceLastActivity > 240000 && userStatus !== "away") {
				socket.emit("userAway", user?._id);
				userStatus = "away"; // Update the status
			} else if (timeSinceLastActivity <= 240000 && userStatus !== "online") {
				socket.emit("userOnline", user?._id);
				userStatus = "online"; // Update the status
			}
		}, 1000);

		// Cleanup: Remove listeners and interval on unmount
		return () => {
			clearInterval(checkConnection);
			window.removeEventListener("mousemove", resetActivityTimer);
			window.removeEventListener("keypress", resetActivityTimer);
		};
	}, [socket, user]);

	useEffect(() => {
		(async () => {
			setIsBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) {
		return <Loading />;
	} else {
		return (
			<>
				<AppShell
					header={{ height: 60 }}
					navbar={{
						width: 300,
						breakpoint: "sm",
						collapsed: { mobile: !opened }
					}}
					padding="md"
				>
					<AppShell.Header
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							padding: theme.spacing.sm
						}}
					>
						<ActivityTracker opened={notificationOpened} OpenClose={OpenClose} />
						<Link to="/" style={{ display: "flex", alignItems: "flex-center" }}>
							<Image src="/logo.svg" alt="APIT logo" height={40} />
							<Flex direction={"column"}>
								<Text size="xs" c="cyan" opacity={0.3} style={{ marginTop: 3, marginLeft: 10 }}>
									01.02.25
								</Text>
								<Text size="md" c="cyan" weight={700} style={{ marginTop: 0, marginLeft: 10 }}>
									v1.2
								</Text>
							</Flex>
						</Link>
						<Group
							position="right"
							style={{ margin: "-10px 40px -10px 40px", flex: "0 0 256px" }}
							spacing="md"
						>
							<Center>{mainItems}</Center>
						</Group>
						<Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
						<BreadcrumbsComponent m={10} />
						<Notifications opened={notificationOpened} OpenClose={OpenClose} />
					</AppShell.Header>

					<AppShell.Navbar p="md">
						<Sidebar />
					</AppShell.Navbar>

					<AppShell.Main
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100vh - 92px)"
						}}
					>
						<Component user={user} />
					</AppShell.Main>
				</AppShell>
			</>
		);
	}
}
