import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useUser } from "../../../helpers/userContext";
import { useLang } from "../../../helpers/language";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Software() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.software;
	const { user } = useUser();
	const { lang } = useLang();

	const [index, setIndex] = useState("");
	const [name, setName] = useState("");
	const [version, setVersion] = useState("");
	const [permissions, setPermissions] = useState([]);

	const [file, setFile] = useState("");

	const modelSendToServer = {
		index,
		name,
		version
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: { _id: id },
					limit: 1
				});
				setIndex(currentItem[0]?.index);
				setName(currentItem[0]?.name);
				setVersion(currentItem[0]?.version);

				setFile(undefined);
				currentItem[0]?.uploads?.map((item) => {
					if (item.destiny === "file") setFile(item);
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "software") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				permissionModel="software"
				permissions={permissions}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: name,
						setter: setName,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "name"
					},
					{
						value: version,
						setter: setVersion,
						type: "text",
						usageType: "textfield",
						label: T("version", lang),
						placeholder: T("version", lang),
						permissionModel: "version"
					}
				]}
				filesComponent={[
					{
						destiny: "file",
						file: file,
						setFile: setFile,
						filesUploadLimit: 1,
						acceptedFiles: ["application/*"],
						buttonAddText: "Add",
						buttonOpenText: "Open",
						buttonRemoveText: "Remove",
						dropzoneText: "There is no file, click to upload a new one",
						adminMode: user.role !== "editor",
						permissionModel: "file"
					}
				]}
			/>
		);
}
