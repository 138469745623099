import React, { useState, useEffect } from "react";
import {
	Button,
	Checkbox,
	CloseIcon,
	Flex,
	Group,
	Image,
	Modal,
	Text,
	useMantineColorScheme
} from "@mantine/core";
import moment from "moment/moment";
import ActionsMenu from "../actions-menu/ActionsMenu";
import classes from "./message.module.css";
import { useLang } from "../../../helpers/language";
import config from "../../../config";
import UserInfo from "../user-info/UserInfo";
import parse from "html-react-parser";
import { IconCheck, IconChecks, IconShare2 } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import Swal from "sweetalert2";
import { T, Translations } from "../../../helpers/translator";
import * as API from "../../../helpers/api";
import ImageGallery from "react-image-gallery";
import { useMantineTheme } from "@mantine/core";
import { useInViewport } from "@mantine/hooks";
import AudioPlayerWithWaveform from "../../../helpers/AudioPlayer";

const Message = ({
	message = {},
	fromUser = {}, // will be object
	toUser = {}, // will be object
	user = {}, // will be object
	onRead = () => {},
	onReply = () => {},
	onForward = () => {},
	onStarred = () => {},
	onPinned = () => {},
	onDelete = (deleteForEveryone = false) => {},
	hideActions = false,
	isBusy,
	setBusy,
	setFiles
}) => {
	const { lang } = useLang();
	const [showActions, setShowActions] = useState(false);
	const [userInfoDrawerOpen, setUserInfoDrawerOpen] = useState(false);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [deleteForEveryone, setDeleteForAll] = useState(false);
	const [fullScreen, setFullScreen] = useState(false);
	const { colorScheme } = useMantineColorScheme();
	const theme = useMantineTheme();
	const [opened, { open, close }] = useDisclosure(false);
	const [modalStartIndex, setModalStartIndex] = useState(0);
	const { ref, inViewport } = useInViewport();
	var fileId = "";
	const [fromUserUploads, setFromUserUploads] = useState([]);

	// get message.from user uplaods
	useEffect(() => {
		if (message.from?._id) {
			(async () => {
				try {
					const uploads = await API.get({
						route: config.api.upload,
						query: { obj_id: message.from._id }
					});
					setFromUserUploads(uploads);
				} catch (err) {
					console.error(err);
				}
			})();
		}
		// eslint-disable-next-line
	}, []);

	async function removeFile({ id }) {
		if (id) {
			Swal.fire({
				title: T("areYouSure", lang),
				text: T("youCannotRevertThisAction", lang),
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: T("yes", lang)
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (message.uploads) {
						try {
							await API.remove(config.api.upload, id);
							setBusy(true);
							setFiles([]);
						} catch (err) {
							console.error(err);
							Swal.fire({
								title: "Error!",
								text: Translations.swal.error[lang],
								icon: "error",
								confirmButtonText: "Ok"
							});
						}
					}
				}
			});
		}
	}

	useEffect(() => {
		let onReadInterval;
		if (inViewport) {
			if (message.to.includes(user._id) && !message.readBy.includes(user._id)) {
				onReadInterval = setInterval(() => {
					onRead(user._id);
				}, 1000);
			}
		}
		return () => {
			if (onReadInterval) {
				clearInterval(onReadInterval);
			}
		};
	}, [inViewport, message.readBy, message.to, onRead, user._id]);

	useEffect(() => {
		function handleKeyDown(event) {
			if (event.key === "Enter" && deleteConfirmationOpen) {
				onDelete(deleteForEveryone);
				setDeleteConfirmationOpen(false);
			}
		}

		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [deleteConfirmationOpen, deleteForEveryone]);

	return (
		<>
			<Flex
				ref={ref}
				justify={"space-between"}
				align={"center"}
				onMouseEnter={() => {
					setShowActions(true);
				}}
				onMouseLeave={() => {
					setShowActions(false);
				}}
				className={classes.wrapper}
			>
				<Flex key={message.id}>
					<Flex className={classes.imageWrapper}>
						<Image
							src={`${config.api.API_URL}/${fromUserUploads?.[0]?.path}` || ""}
							className={classes.image}
							alt="fromUser"
						/>
					</Flex>
					<Flex direction="column">
						<Flex align={"center"}>
							<span style={{ cursor: "pointer" }} onClick={() => setUserInfoDrawerOpen(true)}>
								{fromUser.name?.[lang]}
							</span>
							<span className={classes.time}>{moment(message.created).format("HH:mm")}</span>
						</Flex>
						<Text>{parse(message?.body ? message?.body : "")}</Text>
						{message.uploads && message.uploads.length > 0 && (
							<Flex direction="row" gap={20} mt="sm">
								{message.uploads.map((file, index) => (
									<Flex key={index} align="center" mt="xs">
										{file.type?.startsWith("image/") ? (
											<div key={index} style={{ position: "relative" }}>
												<Image
													src={`${config.api.API_URL}/${file.path}`}
													alt={`${file.alt || file.path || ""}`}
													w={"100%"}
													h={"140px"}
													radius="sm"
													style={{
														objectFit: "cover"
													}}
												/>
												{!fullScreen && (
													<>
														<Button
															onClick={() => removeFile({ id: file._id })}
															color="red"
															id={fileId}
															style={{
																position: "absolute",
																top: 5,
																right: 5,
																opacity: 0.8,
																width: "20px",
																height: "20px",
																padding: "0"
															}}
														>
															<CloseIcon
																size={14}
																color={colorScheme === "dark" ? "white" : "black"}
																m={0}
															/>
														</Button>

														<Button
															onClick={(e) => {
																open(true);
																setModalStartIndex(message.uploads?.indexOf(file));
															}}
															color="cyan"
															id={fileId}
															style={{
																position: "absolute",
																bottom: 5,
																right: 5,
																opacity: 0.8,
																width: "30px",
																height: "30px",
																padding: "0"
															}}
														>
															<IconShare2
																size={14}
																color={colorScheme === "dark" ? "white" : "black"}
																m={0}
															/>
														</Button>
													</>
												)}
											</div>
										) : file.type?.startsWith("audio/") ? (
											<Flex w={300} align="center" gap="xs">
												<AudioPlayerWithWaveform audioUrl={`${config.api.API_URL}/${file.path}`} />
											</Flex>
										) : file.type?.startsWith("video/") ? (
											<video
												key={index}
												controls
												style={{
													width: "100%",
													height: "140px",
													objectFit: "cover"
												}}
											>
												<source src={`${config.api.API_URL}/${file.path}`} type={file.type} />
												Your browser does not support the video tag.
											</video>
										) : (
											<Text className={classes.attachmentFile}>
												{file.name}
												{file.extension}
											</Text>
										)}
										<Modal opened={opened} onClose={close} centered fullScreen>
											<ImageGallery
												startIndex={modalStartIndex}
												slideDuration={200}
												showThumbnails={true}
												items={message.uploads?.map((file, key) => {
													file.thumbnailHeight = "100px";
													file.thumbnail = `${config.api.API_URL}/${file.path}`;
													file.renderItem = () => {
														return (
															<Image
																src={`${config.api.API_URL}/${file.path}`}
																alt={`${file.name}`}
																w={"auto"}
																m={"0 auto"}
																h={"calc(100vh - 200px)"}
																objectFit={"contain"}
																key={key}
															/>
														);
													};
													file.renderThumbInner = () => {
														return (
															<Image
																src={`${config.api.API_URL}/${file.path}`}
																alt={`${file.name}`}
																style={{
																	height: "100px"
																}}
															/>
														);
													};
													return file;
												})}
												onScreenChange={(fullScreen) => {
													if (fullScreen) {
														document.body.style.overflow = "hidden";
														setFullScreen(true);
													} else {
														document.body.style.overflow = "auto";
														setFullScreen(false);
													}
												}}
											/>
										</Modal>
									</Flex>
								))}
							</Flex>
						)}
						{/* {Object.keys(message.forward).length > 0 && (
							<Blockquote
								color="grey"
								cite={`Forwarded from - ${message.forward?.from?.name?.[lang]}`}
								mt="sm"
							>
								{parse(message?.forward?.body ? message?.forward?.body : "")}
							</Blockquote>
						)} */}
					</Flex>
				</Flex>
				<Flex align={"center"} mt="sm">
					{message.from?._id === fromUser?._id ? (
						message.readBy.includes(toUser?._id) ? (
							// Double checks for read
							<IconChecks size={24} color={theme.colors.green[9]} m={0} />
						) : (
							// Single check for sent but not read
							<IconCheck size={24} color={theme.colors.gray[7]} m={0} />
						)
					) : null}
				</Flex>
				{!hideActions && showActions && (
					<ActionsMenu
						onReply={onReply}
						onForward={onForward}
						onDelete={() => {
							setDeleteConfirmationOpen(true);
						}}
						onStarred={onStarred}
						onPinned={onPinned}
						pinned={message.pinned}
						starred={message.starred}
					/>
				)}
			</Flex>
			<UserInfo
				open={userInfoDrawerOpen}
				onClose={() => setUserInfoDrawerOpen(false)}
				data={fromUser?._id === user?._id ? user : toUser}
			/>
			<Modal
				opened={deleteConfirmationOpen}
				onClose={() => setDeleteConfirmationOpen(false)}
				title="Delete message"
				centered
				overlayProps={{
					backgroundOpacity: 0.55,
					blur: 3
				}}
			>
				<Text>{T("areYouSureToDelete", lang)}</Text>
				{message.from?._id === user?._id ? (
					<Checkbox
						mt="md"
						checked={deleteForEveryone}
						onChange={(e) => {
							setDeleteForAll(e.currentTarget.checked);
						}}
						label={T("deleteForEveryone", lang)}
					/>
				) : null}
				<Group mt="lg">
					<Button
						variant="filled"
						onClick={() => {
							onDelete(deleteForEveryone);
							setDeleteConfirmationOpen(false);
						}}
					>
						{T("delete", lang)}
					</Button>
					<Button variant="default" onClick={() => setDeleteConfirmationOpen(false)}>
						{T("cancel", lang)}
					</Button>
				</Group>
			</Modal>
		</>
	);
};
export default Message;
