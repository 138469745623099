import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSocket } from "../../helpers/socket";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";
import parse from "html-react-parser";
import { useMobile } from "../../helpers/is-mobile";
import { Text, Modal, Button, List, ScrollArea, Notification, Badge } from "@mantine/core";
import { T } from "../../helpers/translator";
import { useHistory } from "react-router-dom";

export default function Notifications({ opened, OpenClose }) {
	const { myNotifications, socket } = useSocket();
	const audio = useRef(null);
	const { lang } = useLang();
	const isMobile = useMobile();
	const history = useHistory();
	const { user } = useUser();

	function updateTabTitle(notifications) {
		if (notifications > 0) {
			document.title = `APIT - ${notifications} ${T("notifications", lang)}`;
		} else {
			document.title = "APIT";
		}
	}
	// Calculate unread notifications
	const unreadNotifications = myNotifications.filter(
		(notification) => !notification.read.includes(user._id)
	);

	useEffect(() => {
		if (myNotifications.length > 0) {
			let lastNotification = myNotifications[myNotifications.length - 1];

			// Check for the correct route using indexOf
			if (lastNotification.route.indexOf("project") !== -1) {
				audio.current.src = "/assets/discord.mp3";
			} else if (lastNotification.route.indexOf("ticket") !== -1) {
				audio.current.src = "/assets/meet.mp3";
			}

			// Reload the audio to ensure the new source is applied
			audio.current.load();

			// Play the audio once it's loaded
			audio?.current?.play().catch((err) => console.warn("Audio play blocked:", err));

			// Interval for updating the title
			let interval = setInterval(() => {
				updateTabTitle(unreadNotifications.length);
				setTimeout(() => {
					updateTabTitle(0);
				}, 1500);
			}, 3000);

			return () => {
				document.title = "APIT Admin";
				clearInterval(interval);
			};
		}
		// eslint-disable-next-line
	}, [myNotifications]);

	return (
		<>
			<audio ref={audio} />
			<Button
				justify="center"
				width
				onClick={OpenClose.toggle}
				p={5}
				size="xs"
				variant="default"
				mt={5}
				leftSection={
					unreadNotifications.length > 0 && (
						<Badge color="red" size="lg">
							{unreadNotifications.length}
						</Badge>
					)
				}
			>
				{isMobile ? "" : T("notifications", lang)}
			</Button>
			<Modal
				opened={opened}
				onClose={OpenClose.close}
				title={T("notifications", lang)}
				centered
				size="md"
				overlayProps={{
					backgroundOpacity: 0.55,
					blur: 3
				}}
				scrollAreaComponent={ScrollArea.Autosize}
			>
				{myNotifications?.length > 0 ? (
					// <ScrollArea style={{ maxHeight: "50vh" }} className="mantine-scroll-area">
					<List shadow="xs" padding="xs" styles={{ list: { padding: 0 } }}>
						{myNotifications?.map((notification, index) => {
							return (
								<List.Item
									style={{
										cursor: "pointer",
										userSelect: "none",
										listStyleType: "none",
										position: "relative",
										opacity: notification.read.includes(user._id) ? 0.3 : 1
									}}
									onClick={() => {
										socket.emit(
											"readNotification",
											JSON.stringify({
												userId: user._id,
												notificationId: notification._id
											})
										);
										OpenClose.close();
										history.go(notification?.route ? "/" + notification?.route : "/");
									}}
									key={index}
									hover
									mt={20}
									mb={40}
									listStyleType="none"
								>
									<Notification
										title={notification.name?.[lang]}
										color={
											notification.importance === "high"
												? "red"
												: notification.importance === "medium"
													? "orange"
													: "blue"
										}
										withCloseButton={false}
									>
										{parse(notification?.desc?.[lang] ? notification?.desc?.[lang] : "")}
									</Notification>
									<Button
										style={{
											position: "absolute",
											right: 0,
											top: 0,
											bottom: 0,
											height: "100%",
											opacity: 0.5
										}}
										color="red"
										size="xs"
										onClick={(e) => {
											e.stopPropagation();
											socket.emit(
												"removeNotification",
												JSON.stringify({
													userId: user._id,
													notificationId: notification._id
												})
											);
											OpenClose.close();
										}}
									>
										{T("remove", lang)}
									</Button>
									<Text
										style={{
											position: "absolute",
											right: 0,
											top: -30,
											padding: "5px",
											color: "gray",
											fontSize: "0.8rem"
										}}
									>
										{moment(notification.createdAt).fromNow()} -{" "}
										{moment(notification.createdAt).format("MM/HH/DD")}
									</Text>
								</List.Item>
							);
						})}
					</List>
				) : (
					// </ScrollArea>
					<Text align="center" size="xl">
						{T("noNotifications", lang)}
					</Text>
				)}
			</Modal>
		</>
	);
}
