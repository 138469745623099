import { useEffect, useRef } from "react";
import * as API from "../../helpers/api";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import { useSocket } from "../../helpers/socket";

const ActivityTracker = ({ opened, OpenClose }) => {
	const { user } = useUser();
	const { myNotifications, socket } = useSocket();
	const lastActivityTime = useRef(Date.now());
	const activeTimeRef = useRef(0);
	const inactiveTimeRef = useRef(0);
	const totalTimeRef = useRef(0); // Total time within a 10-second window
	const activityIntervalRef = useRef(null);
	const idleTimeoutRef = useRef(null);

	// Function to check idle time every second
	const checkIdleTime = () => {
		const now = Date.now();
		const idleDuration = Math.floor((now - lastActivityTime.current) / 1000);

		// If idle for 12 seconds or more, trigger the OpenClose.open()
		if (idleDuration >= 12) {
			if (
				myNotifications.length > 0 &&
				myNotifications.filter((n) => !n.read.includes(user._id)).length > 0
			) {
				OpenClose.open();
			}
		}
	};

	// Function to send activity data to the server
	const sendActivityData = () => {
		// Send the data to the backend
		API.post(config.api.activityTracker, {
			userId: user._id,
			activeTime: activeTimeRef.current,
			inactiveTime: inactiveTimeRef.current
		});

		// Reset times after sending
		activeTimeRef.current = 0;
		inactiveTimeRef.current = 0;
		totalTimeRef.current = 0; // Reset total time after every 10-second period
	};

	useEffect(() => {
		// Start an interval to check idle time every second
		activityIntervalRef.current = setInterval(() => {
			const now = Date.now();
			const elapsed = Math.floor((now - lastActivityTime.current) / 1000);

			// If the user is active (less than 5 seconds idle), increment active time
			if (elapsed < 5) {
				activeTimeRef.current += 1;
			} else {
				// If the user is inactive for more than 5 seconds, increment inactive time
				inactiveTimeRef.current += 1;
			}

			// Increment total time each second, capped at 10 seconds
			if (totalTimeRef.current < 10) {
				totalTimeRef.current += 1;
			}

			// If total time reaches 10 seconds, send the data and reset counters
			if (totalTimeRef.current >= 10) {
				sendActivityData();
			}

			// Check idle time independently of mouse events
			checkIdleTime();
		}, 20000);

		// Reset the idle timer whenever there's activity
		const resetIdleTimer = () => {
			lastActivityTime.current = Date.now();
		};

		// Attach activity event listeners to reset the idle timer (mouse, key, scroll)
		window.addEventListener("mousemove", resetIdleTimer);
		window.addEventListener("keydown", resetIdleTimer);
		window.addEventListener("scroll", resetIdleTimer);

		// Cleanup on component unmount
		return () => {
			clearInterval(activityIntervalRef.current);
			window.removeEventListener("mousemove", resetIdleTimer);
			window.removeEventListener("keydown", resetIdleTimer);
			window.removeEventListener("scroll", resetIdleTimer);
		};
	}, []);

	return null; // This component doesn't render anything, just tracks activity
};

export default ActivityTracker;
