import React, { useEffect, useState } from "react";
import { Blockquote, Box, Button, Flex, Modal, Select } from "@mantine/core";
import * as API from "../../../helpers/api";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import { useUser } from "../../../helpers/userContext";
import { T } from "../../../helpers/translator";
import parse from "html-react-parser";

const Forward = ({ opened, onClose, data: { forwardUser, forwardMessage } }) => {
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState("");
	const [message, setMessage] = useState("");
	const { lang } = useLang();
	const { user } = useUser();
	const editor = useEditor({
		extensions: [
			StarterKit,
			Placeholder.configure({ placeholder: "This is placeholder" }),
			Underline,
			TextAlign,
			Superscript,
			SubScript
		],
		content: message,
		onUpdate: ({ editor }) => {
			setMessage(editor.getHTML());
		}
	});
	useEffect(() => {
		(async () => {
			const usersData = await API.get({
				route: config.api.user
			});
			if (usersData) {
				setUsers(usersData.map((user) => ({ label: user?.name[lang], value: user._id })));
			}
		})();
	}, []);

	const onForwardSend = async () => {
		try {
			await API.post(config.api.message, {
				from: user._id,
				to: [selectedUser],
				body: message,
				created: new Date(),
				forward: {
					from: forwardUser?._id,
					body: forwardMessage
				}
			});
			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Modal
			opened={opened}
			title={T("forwardMessage", lang)}
			centered
			onClose={onClose}
			size="lg"
			overlayProps={{
				backgroundOpacity: 0.55,
				blur: 3
			}}
		>
			<RichTextEditor editor={editor}>
				<RichTextEditor.Toolbar sticky stickyOffset={60}>
					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Bold />
						<RichTextEditor.Italic />
						<RichTextEditor.Underline />
						<RichTextEditor.Strikethrough />
						<RichTextEditor.ClearFormatting />
						<RichTextEditor.Highlight />
						<RichTextEditor.Code />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.H1 />
						<RichTextEditor.H2 />
						<RichTextEditor.H3 />
						<RichTextEditor.H4 />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Blockquote />
						<RichTextEditor.Hr />
						<RichTextEditor.BulletList />
						<RichTextEditor.OrderedList />
						<RichTextEditor.Subscript />
						<RichTextEditor.Superscript />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Link />
						<RichTextEditor.Unlink />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.AlignLeft />
						<RichTextEditor.AlignCenter />
						<RichTextEditor.AlignJustify />
						<RichTextEditor.AlignRight />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Undo />
						<RichTextEditor.Redo />
					</RichTextEditor.ControlsGroup>
				</RichTextEditor.Toolbar>
				<RichTextEditor.Content />
			</RichTextEditor>
			<Select
				placeholder="Select user"
				data={users}
				searchable
				mt="md"
				onChange={setSelectedUser}
			/>
			<Box mt="md">
				<Blockquote
					color="grey"
					cite={`${T("forwardedFrom", lang)} - ${forwardUser?.name[lang]}`}
					mt="sm"
				>
					{forwardMessage
						? parse(
								forwardMessage.length > 40 ? forwardMessage?.slice(0, 100) + "..." : forwardMessage
							)
						: ""}
				</Blockquote>
				<Flex justify="end" mt="md">
					<Button onClick={onForwardSend} disabled={!selectedUser}>
						{T("send", lang)}
					</Button>
				</Flex>
			</Box>
		</Modal>
	);
};
export default React.memo(Forward);
