import React, { useEffect } from "react";
import {
	Stepper as MantineStepper,
	MantineProvider,
	Card,
	useMantineTheme,
	Grid,
	useMantineColorScheme,
	Checkbox,
	Group,
	Box,
	Text,
	Slider,
	Flex,
	Title,
	Button,
	Divider,
	ScrollArea,
	Collapse
} from "@mantine/core";
import { debounce } from "lodash";
import { useState } from "react";
import NProgress, { status } from "nprogress";
import * as API from "../../../helpers/api";
import config from "../../../config";
import { T, Translations } from "../../../helpers/translator";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useLang } from "../../../helpers/language";
import Input from "../../modules/input";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
	IconClearFormatting,
	IconPlayerTrackNextFilled,
	IconSearch,
	IconTrash
} from "@tabler/icons-react";
import { IconPlayerTrackPrevFilled } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

const Stepper = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [isBusy, setBusy] = useState(true);
	const { lang } = useLang();
	const Theme = useMantineTheme();
	const { colorScheme } = useMantineColorScheme();
	const [showTable, setShowTable] = useState(false);
	const [showNoContent, setShowNoContent] = useState(false);
	const [projects, setProjects] = useState([]);

	const [projectType, setProjectType] = useState("");
	const [projectTypes, setProjectTypes] = useState([]);

	const [community, setCommunity] = useState("");
	const [communities, setCommunities] = useState([]);

	const [step3SelectedResponsibles, setStep3SelectedResponsibles] = useState("");
	const [selectedSteps, setSelectedSteps] = useState([]);
	const [stepStatus, setStepStatus] = useState([]);

	const [branch, setBranch] = useState(null);
	const [branches, setBranches] = useState([]);

	const [voltage, setVoltage] = useState(null);
	const [voltages, setVoltages] = useState([]);
	const [projectPartition, setProjectPartition] = useState("");
	const [disabledSteps, setDisabledSteps] = useState([]);
	const [users, setUsers] = useState([]);
	const [importance, setImportance] = useState("");

	const [opened, Toggle] = useDisclosure(false);
	const [opened2, Toggle2] = useDisclosure(true);
	const [openedInner, ToggleInner] = useDisclosure(true);

	const columns = [
		{
			accessorKey: "ID",
			permissionModel: "ID",
			header: "ID",
			size: 30,
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				return (
					<Link
						to={`/project/edit/${ReceivedObject.row.original._id}`}
						style={{ textDecoration: "none" }}
					>
						{cell}
					</Link>
				);
			}
		},
		{
			accessorKey: "steps",
			header: T("steps", lang),
			permissionModel: "steps",
			filterVariant: "multi-select",
			grow: true,
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				let result;
				cell?.forEach((item, index) => {
					if (item.status === "inProgress") {
						result = { ...item, index };
						return item;
					}
				});
				// result && result.index ? result.index : "Not started";
				let value = result && result.index + 1 ? result.index + 1 : 0;
				let label = value
					? config.steps[result.index]?.name?.[lang]
					: config.steps[0]?.name?.[lang];
				return (
					<Flex direction="column" w={"100%"}>
						<Text size="sm" mb={10}>
							{label}
						</Text>

						<Slider
							thumbChildren={<Text size="1rem">{value}</Text>}
							label={null}
							max={19}
							defaultValue={0}
							thumbSize={30}
							styles={{
								thumb: { borderWidth: "1px", padding: "rem(3)" },
								width: "100%"
							}}
							step={1}
							min={1}
							value={value}
						/>
					</Flex>
				);
			}
		},
		{
			accessorKey: "priority",
			header: T("priority", lang),
			permissionModel: "priority",
			size: 200,
			filterVariant: "multi-select",
			//custom conditional format and styling
			Cell: (ReceivedObject, cell = ReceivedObject.renderedCellValue) => {
				if (cell) {
					return (
						<Box
							sx={(theme) => ({
								backgroundColor:
									cell === 0
										? theme.colors.green[9]
										: cell === 1
											? theme.colors.yellow[9]
											: cell === 2
												? theme.colors.red[9]
												: theme.colors.gray[9],
								borderRadius: "10px",
								color: "#fff",
								maxWidth: "150px",
								padding: "5px",
								textAlign: "center"
							})}
						>
							{cell === 0
								? T("high", lang)
								: cell === 1
									? T("medium", lang)
									: cell === 2
										? T("low", lang)
										: T("undefined", lang)}
						</Box>
					);
				} else {
					return <Text>{T("undefined", lang)}</Text>;
				}
			}
		}
	];

	// hardcoded information
	const projectPartitions = [
		{
			_id: "0",
			name: {
				en: "Main",
				ru: "Основной",
				hy: "Հիմնական",
				tr: "Ana",
				ar: "رئيسي",
				zh: "主要",
				fa: "اصلی",
				he: "ראשי",
				fr: "Principal",
				es: "Principal",
				pt: "Principal"
			}
		},
		{
			_id: "1",
			name: {
				en: "Partition",
				ru: "Подразделение",
				hy: "Հատոր",
				tr: "Bölüm",
				ar: "تقسيم",
				zh: "分区",
				fa: "پارتیشن",
				he: "מחיצה",
				fr: "Partition",
				es: "Partición",
				pt: "Partição"
			}
		},
		{
			_id: "2",
			name: {
				en: "Project change",
				ru: "Изменение проекта",
				hy: "Նախագծի փոփոխություն",
				tr: "Proje değişikliği",
				ar: "تغيير المشروع",
				zh: "项目变更",
				fa: "تغییر پروژه",
				he: "שינוי פרויקט",
				fr: "Changement de projet",
				es: "Cambio de proyecto",
				pt: "Mudança de projeto"
			}
		}
	];

	const importances = [
		{
			_id: "2",
			name: {
				en: "Low",
				ru: "Низкий",
				hy: "Ցածր",
				tr: "Düşük",
				ar: "منخفض",
				zh: "低",
				fa: "کم",
				he: "נמוך",
				fr: "Faible",
				es: "Bajo",
				pt: "Baixo"
			}
		},
		{
			_id: "1",
			name: {
				en: "Medium",
				ru: "Средний",
				hy: "Միջին",
				tr: "Orta",
				ar: "متوسط",
				zh: "中",
				fa: "متوسط",
				he: "בינוני",
				fr: "Moyen",
				es: "Medio",
				pt: "Médio"
			}
		},
		{
			_id: "0",
			name: {
				en: "High",
				ru: "Высокий",
				hy: "Բարձր",
				tr: "Yüksek",
				ar: "عالي",
				zh: "高",
				fa: "زیاد",
				he: "גבוה",
				fr: "Élevé",
				es: "Alto",
				pt: "Alto"
			}
		}
	];

	const disabledStepsArray = [
		{
			name: {
				hy: "Տիմ մուտք պետք չէ",
				en: "No team entry required",
				ru: "Вход команды не требуется",
				tr: "Takım girişi gerekli değil",
				ar: "لا يلزم إدخال الفريق",
				zh: "不需要团队输入",
				fa: "ورود تیم لازم نیست",
				he: "אין צורך בכניסת צוות",
				fr: "Pas d'entrée d'équipe requise",
				es: "No se requiere entrada de equipo",
				pt: "Nenhuma entrada de equipe necessária"
			},
			value: [10, 13, 14, 17, 18, 19, 20, 39, 40, 41, 42, 43, 44]
		},
		{
			name: {
				hy: "Տեղազննում պետք չէ",
				en: "No site inspection required",
				ru: "Осмотр сайта не требуется",
				tr: "Site denetimi gerekli değil",
				ar: "لا يلزم التفتيش على الموقع",
				zh: "不需要现场检查",
				fa: "بازرسی سایت لازم نیست",
				he: "אין צורך בבדיקת אתר",
				fr: "Pas d'inspection de site requise",
				es: "No se requiere inspección del sitio",
				pt: "Nenhuma inspeção no local necessária"
			},
			value: [1]
		},
		{
			name: {
				hy: "Գրունտ պետք չէ",
				en: "No soil required",
				ru: "Почва не требуется",
				tr: "Toprak gerekli değil",
				ar: "لا يلزم التربة",
				zh: "不需要土壤",
				fa: "خاک لازم نیست",
				he: "אין צורך באדמה",
				fr: "Pas de sol requis",
				es: "No se requiere suelo",
				pt: "Nenhum solo necessário"
			},
			value: [2]
		},
		{
			name: {
				hy: "Նախահաշիվ պետք չէ",
				en: "No preliminary estimate required",
				ru: "Предварительная оценка не требуется",
				tr: "Ön tahmin gerekli değil",
				ar: "لا يلزم تقدير أولي",
				zh: "不需要初步估计",
				fa: "برآورد اولیه لازم نیست",
				he: "אין צורך בהערכה מוקדמת",
				fr: "Pas d'estimation préliminaire requise",
				es: "No se requiere estimación preliminar",
				pt: "Nenhuma estimativa preliminar necessária"
			},
			value: [28, 29, 30, 31, 32, 33]
		},
		{
			name: {
				hy: "Նախնական ՀԷՑ հանձնելու կարիք չկա",
				en: "No need to submit to preliminary HEC",
				ru: "Нет необходимости подавать в предварительный ГЭК",
				tr: "Ön HEC'ye sunmaya gerek yok",
				ar: "لا حاجة لتقديم إلى HEC الأولي",
				zh: "无需提交初步HEC",
				fa: "نیازی به ارسال به HEC اولیه نیست",
				he: "אין צורך להגיש ל-HEC ראשוני",
				fr: "Pas besoin de soumettre au HEC préliminaire",
				es: "No es necesario presentar al HEC preliminar",
				pt: "Não há necessidade de enviar ao HEC preliminar"
			},
			value: [15, 16]
		},
		{
			name: {
				hy: "Գծագրում պետք չէ",
				en: "No drawing required",
				ru: "Чертеж не требуется",
				tr: "Çizim gerekli değil",
				ar: "لا يلزم الرسم",
				zh: "不需要绘图",
				fa: "نقشه کشی لازم نیست",
				he: "אין צורך בציור",
				fr: "Pas de dessin requis",
				es: "No se requiere dibujo",
				pt: "Nenhum desenho necessário"
			},
			value: [4, 5, 6, 7, 8, 9, 11, 12]
		}
	];

	const stepStatuses = [
		{
			_id: "0",
			name: Translations.stepIsNotStartedYet,
			value: "notStarted"
		},
		{
			_id: "1",
			name: Translations.inProgress,
			value: "inProgress"
		},
		{
			_id: "2",
			name: Translations.done,
			value: "done"
		}
	];

	const handleSearch = async () => {
		NProgress.start();
		try {
			let query = {
				projectType: projectType || undefined,
				community: community || undefined,
				branch: branch || undefined,
				voltage: voltage || undefined,
				projectPartition: projectPartition || undefined,
				importance: Number(importance) || undefined,
				disabledSteps: disabledSteps.length > 0 ? disabledSteps : undefined
			};

			// Remove undefined values from query
			Object.keys(query).forEach((key) => query[key] === undefined && delete query[key]);

			let projects = await API.get({
				route: config.api.project,
				query: query,
				uploadType: true,
				populate: [
					"steps",
					"city",
					"village",
					"branch",
					{
						path: "branch",
						populate: [
							{
								path: "steps.responsible"
							},
							{
								path: "steps.users"
							}
						]
					},
					"voltage",
					{
						path: "voltage",
						populate: [
							{
								path: "steps.responsible"
							},
							{
								path: "steps.users"
							}
						]
					},
					"projectType",
					{
						path: "projectType",
						populate: {
							path: "steps.responsible"
						}
					},
					"community"
				]
			});

			if (projects?.length > 0) {
				setShowTable(true);
				setShowNoContent(false);
				let tempProjects = [...projects];

				if (step3SelectedResponsibles) {
					const filteredProjects = await Promise.all(
						tempProjects.map(async (project) => {
							let stepResponsibles = project?.steps
								?.find((item) => item.queue === 3)
								?.steps?.find((st) => st.index === 4)?.responsible;
							if (stepResponsibles?.[0] === step3SelectedResponsibles) {
								let responsibles = await API.get({
									route: config.api.user,
									query: {
										_id: {
											$in: stepResponsibles
										}
									},
									uploadType: true
								});
								if (responsibles) return project;
							}
							return null;
						})
					);
					tempProjects = filteredProjects.filter(Boolean);
				}
				if (selectedSteps.length > 0 && stepStatus) {
					let parsedStepStatus = stepStatuses.find((item) => item._id === stepStatus)?.value;
					const filteredProjects = tempProjects.filter((project) => {
						return selectedSteps.every((step) => {
							const projectStep = project.steps.find((s) => s.queue === step);
							if (projectStep?.status === parsedStepStatus) return project;
							return null;
						});
					});
					tempProjects = filteredProjects;
				}
				setProjects(tempProjects);
			} else {
				setShowTable(false);
				setShowNoContent(true);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setShowTable(false);
			setShowNoContent(true);
		}
		NProgress.done();
	};

	const nextStep = () => {
		setActiveStep((current) => (current < 9 ? current + 1 : current));
	};

	const prevStep = () => {
		setShowTable(false);
		setShowNoContent(false);
		setActiveStep((current) => (current > 0 ? current - 1 : current));
	};

	useEffect(() => {
		const observer = new ResizeObserver(
			debounce(() => {
				console.log("Resize detected");
			}, 100) // Adjust debounce timing
		);

		observer.observe(document.body);
		return () => observer.disconnect();
	}, []);

	const table = useMantineReactTable({
		columns: columns,
		data: projects?.length > 0 ? projects : [],
		paginationDisplayMode: "pages",
		positionToolbarAlertBanner: "bottom",
		mantinePaginationProps: {
			radius: "xl",
			size: "lg"
		}
	});

	useEffect(() => {
		(async () => {
			// all users
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setUsers(rawUsers);
			// communities
			let rawCommunities = await API.get({ route: config.api.community });
			if (rawCommunities) setCommunities(rawCommunities);
			// all voltages
			let rawVoltages = await API.get({ route: config.api.voltage });
			if (rawVoltages) setVoltages(rawVoltages);
			// all projectTypes
			let rawProjectTypes = await API.get({ route: config.api.projectType });
			if (rawProjectTypes) setProjectTypes(rawProjectTypes);
			// all branches
			let rawBranches = await API.get({ route: config.api.branch });
			if (rawBranches) setBranches(rawBranches);

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, []);

	const clearAllFilters = () => {
		setProjectType(null);
		setCommunity(null);
		setBranch(null);
		setVoltage(null);
		setProjectPartition(null);
		setDisabledSteps([]);
		setSelectedSteps([]);
		setStepStatus(null);
		setStep3SelectedResponsibles(null);
		setImportance(null);
		setShowTable(false);
	};

	return (
		<Card shadow="xs" padding="md" style={{ marginBottom: "20px" }}>
			<Flex wrap={"wrap"} align={"center"} gap={20} justify={"center"}>
				<Button
					onClick={() => {
						Toggle.toggle();
					}}
					variant={opened ? "filled" : "outline"}
				>
					{T("detailedFilters", lang)}
				</Button>

				<Button
					onClick={() => {
						Toggle2.toggle();
						if (opened2) ToggleInner.close();
						else ToggleInner.open();
					}}
					variant={opened2 ? "filled" : "outline"}
				>
					{T("preDefinedFilters", lang)}
				</Button>
			</Flex>
			<Flex mb={20} direction={"column"}>
				<Collapse
					style={{ flex: "1 1 auto" }}
					in={opened}
					transitionDuration={250}
					transitionTimingFunction="ease-in-out"
				>
					<Title order={2} mb={10} mt={30} style={{ textAlign: "center" }}>
						{T("detailedFilters", lang)}
					</Title>
					<Flex justify="flex-start" align="center" gap={20} p={20} wrap="wrap">
						{projectTypes && projectTypes.length > 0 && (
							<Input
								value={projectType}
								setter={setProjectType}
								optionListValue={projectTypes}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("projectType", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{communities && communities.length > 0 && (
							<Input
								value={community}
								setter={setCommunity}
								optionListValue={communities}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("community", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{branches && branches.length > 0 && (
							<Input
								value={branch}
								setter={setBranch}
								optionListValue={branches}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("branch", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{users && users.length > 0 && (
							<Input
								value={step3SelectedResponsibles}
								setter={setStep3SelectedResponsibles}
								optionListValue={users}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("responsible", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{voltages && voltages.length > 0 && (
							<Input
								value={voltage}
								setter={setVoltage}
								optionListValue={voltages}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("voltageLevel", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{projectPartitions && projectPartitions.length > 0 && (
							<Input
								value={projectPartition}
								setter={setProjectPartition}
								optionListValue={projectPartitions}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("projectPartition", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{importances && importances.length > 0 && (
							<Input
								value={importance}
								setter={setImportance}
								optionListValue={importances}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("importance", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{disabledStepsArray && disabledStepsArray.length > 0 && (
							<Input
								width={"210px"}
								value={disabledSteps}
								setter={setDisabledSteps}
								optionListValue={disabledStepsArray}
								type="multiSelect"
								usageType="multiSelect"
								lang={lang}
								label={T("bypassSteps", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{config.steps && config.steps.length > 0 && (
							<Input
								width={"210px"}
								value={selectedSteps}
								setter={setSelectedSteps}
								optionListValue={config.steps}
								type="multiSelect"
								usageType="multiSelect"
								lang={lang}
								label={T("selectedSteps", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
						{stepStatuses && stepStatuses.length > 0 && (
							<Input
								value={stepStatus}
								setter={setStepStatus}
								optionListValue={stepStatuses}
								type="optionList"
								usageType="optionList"
								lang={lang}
								label={T("stepStatus", lang)}
								crud={{
									create: true,
									read: true,
									update: true,
									delete: true
								}}
							/>
						)}
					</Flex>
					<Flex justify="space-between" align="center" gap={20} p={20}>
						<Button
							rightSection={<IconSearch size={18} />}
							color="orange"
							variant="light"
							onClick={handleSearch}
						>
							{T("search", lang)}
						</Button>
						{/* creal all filters */}
						<Button
							rightSection={<IconTrash size={18} />}
							color="gray"
							variant="light"
							onClick={clearAllFilters}
						>
							{T("clearFilters", lang)}
						</Button>{" "}
					</Flex>
				</Collapse>
				<Divider my={30} />
				<Collapse
					style={{ flex: "1 1 auto" }}
					in={opened2}
					transitionDuration={250}
					transitionTimingFunction="ease-in-out"
				>
					<Title order={2} mb={10} mt={30} style={{ textAlign: "center" }}>
						{T("preDefinedFilters", lang)}
					</Title>

					<Flex justify="space-between" align="center" gap={20} p={20}>
						<Button
							rightSection={<IconSearch size={18} />}
							color="red"
							variant="light"
							onClick={() => {
								clearAllFilters();
								setImportance("2"); // high importance
								handleSearch();
							}}
						>
							Only High importance projects
						</Button>
						<Button
							rightSection={<IconSearch size={18} />}
							color="orange"
							variant="light"
							onClick={() => {
								clearAllFilters();
								setSelectedSteps(["1"]); // visitation
								setStepStatus("2"); // step done
								handleSearch();
							}}
						>
							All visitations start and Geological exploration end
						</Button>
						<Button
							rightSection={<IconSearch size={18} />}
							color="cyan"
							variant="light"
							onClick={() => {
								clearAllFilters();
								setSelectedSteps(["2"]); // visitation
								setStepStatus("1"); // step done
								handleSearch();
							}}
						>
							All Geological in Progress
						</Button>
						<Button
							rightSection={<IconSearch size={18} />}
							color="cyan"
							variant="light"
							onClick={() => {
								clearAllFilters();
								setVoltage("6640f10e023dea96554d2d9b"); // visitation
								handleSearch();
							}}
						>
							0.2 KV
						</Button>
					</Flex>
				</Collapse>
			</Flex>
			<Divider mb={16} />
			{showTable && (
				<ScrollArea mb={20}>
					<MantineProvider>
						<MantineReactTable table={table} />
					</MantineProvider>
				</ScrollArea>
			)}
			{showNoContent && (
				<Flex justify="center" align="center" p={20}>
					<Text size="lg" weight={500}>
						{T("noContent", lang)}
					</Text>
				</Flex>
			)}
		</Card>
	);
};

export default Stepper;
