import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import moment from "moment";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { useUser } from "../../../helpers/userContext";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Department() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.department;
	const { lang } = useLang();

	const [index, setIndex] = useState("");
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");
	const [descEn, setDescEn] = useState("");
	const [descRu, setDescRu] = useState("");
	const [descHy, setDescHy] = useState("");

	const [users, setUsers] = useState("");
	const [leader, setLeader] = useState(null);
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const columns = [
		{
			accessorKey: "index",
			header: T("index", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.index?.[lang] ? row.index?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row.name?.en} ${row.name?.ru} ${row.name?.hy}`,
			header: T("name", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name?.[lang] ? row.name?.[lang] : "No user");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row.dateOfBirth}`,
			header: T("dateOfBirth", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result =
					moment(row.dateOfBirth).isValid() && moment(row.dateOfBirth).format("DD/MM/YYYY");
				return result;
			}
		}
	];

	const modelSendToServer = {
		index,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		},
		desc: {
			en: descEn,
			ru: descRu,
			hy: descHy
		},
		leader
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true
				});
				setIndex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);
				setDescEn(currentItem[0]?.desc?.en);
				setDescRu(currentItem[0]?.desc?.ru);
				setDescHy(currentItem[0]?.desc?.hy);
				setLeader(currentItem[0]?.leader);

				let rawUsers = await API.get({
					route: config.api.user,
					query: {
						department: id,
						temporary: {
							$ne: true
						},
						workingStatus: "working"
					}
				});
				let departmentUsers = [],
					departmentLeader = {};
				rawUsers = rawUsers.map((user) => {
					if (user._id === leader?._id) departmentLeader = user;
					else departmentUsers = [...departmentUsers, user];
					return user;
				});
				setUsers(rawUsers);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "department") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	useEffect(() => {
		if (leader) {
			let rawUsers = [...users];
			let departmentUsers = [],
				departmentLeader = {};
			rawUsers = rawUsers.map((user) => {
				if (user._id === leader?._id) departmentLeader = user;
				else departmentUsers = [...departmentUsers, user];
				return user;
			});
			setUsers(rawUsers);
		}
	}, [leader]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				modelSendToServer={modelSendToServer}
				connectedRoute={config.api.user}
				connectedID={leader?._id}
				permissionModel="department"
				connectedData={{
					field: "department",
					value: id
				}}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						lang: "en",
						permissionModel: "name"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInRussian", lang),
						lang: "ru",
						permissionModel: "name"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInArmenian", lang),
						lang: "hy",
						permissionModel: "name"
					},
					// leader
					{
						value: leader,
						setter: setLeader,
						optionListValue: users,
						type: "optionList",
						usageType: "optionList",
						label: T("leader", lang),
						placeholder: T("leader", lang),
						permissionModel: "leader"
					},
					{
						value: descEn,
						setter: setDescEn,
						type: "text",
						usageType: "textarea",
						label: T("description", lang),
						placeholder: T("inputInLatin", lang),
						lang: "en",
						width: 12,
						permissionModel: "desc"
					},
					{
						value: descRu,
						setter: setDescRu,
						type: "text",
						usageType: "textarea",
						label: T("description", lang),
						placeholder: T("inputInRussian", lang),
						lang: "ru",
						width: 12,
						permissionModel: "desc"
					},
					{
						value: descHy,
						setter: setDescHy,
						type: "text",
						usageType: "textarea",
						label: T("description", lang),
						placeholder: T("inputInArmenian", lang),
						lang: "hy",
						width: 12,
						permissionModel: "desc"
					}
				]}
				tableComponent={[
					{
						placeholder: T("users", lang),
						data: users,
						columns: columns,
						permissionModel: "users"
					}
				]}
			/>
		);
}
