import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import SinglePage from "../../SinglePage";
import AddPage from "./ticketAdd";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useUser } from "../../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../../helpers/language";
import Swal from "sweetalert2";
import { UPDATE } from "../../../helpers/CRUD";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";
import { useSocket } from "../../../helpers/socket";

export default function Ticket() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	let { search } = useLocation();
	search = search.substring(1).split("=");
	let tempMode = search[0] === "temporary" && search[1] === "true";
	const route = config.api.ticket;
	const { user } = useUser();
	const { lang } = useLang();
	const history = useHistory();

	const [index, setIndex] = useState("");
	const [ID, setID] = useState("");
	const [pcs, setPcs] = useState("");
	const [statuses, setStatuses] = useState("");
	const [status, setStatus] = useState("");
	const [statusForHistory, setStatusForHistory] = useState("");
	const [statusWithoutClose, setStatusWithoutClose] = useState("");
	const [closeStatus, setCloseStatus] = useState("");

	const [rooms, setRooms] = useState("");
	const [printers, setPrinters] = useState("");
	const [softwares, setSoftwares] = useState("");
	const [smbs, setsmbs] = useState("");

	const [importance, setImportance] = useState("low");

	const [desc, setDesc] = useState("");
	const [openedDate, setOpenedDate] = useState(new Date());
	const [closedDate, setClosedDate] = useState("");
	const [History, setHistory] = useState([{}]);
	const [comment, setComment] = useState(undefined);
	const [User, setUser] = useState([{}]);
	const [createdByUser, setcreatedByUser] = useState("");
	const [sendButtonState, setsendButtonState] = useState(true);
	const [lastStatus, setLastStatus] = useState();
	const [firstStatus, setFirstStatus] = useState();
	const [image, setImage] = useState("");
	const [permissions, setPermissions] = useState([]);
	const [ITDepartmentUsers, setITDepartmentUsers] = useState([]);
	const { connectedUsers = [], socket, notifications, setNotifications, myMessages } = useSocket();

	const [objective, setObjective] = useState({
		problem: null,
		problemRelatedList: null,
		pc: {},
		smb: null,
		software: null,
		room: {},
		printer: null
	});

	const [canSendState, setCanSendState] = useState(false);

	const [problems, setProblems] = useState(null);

	const commentColumns = [
		{
			accessorKey: "date",
			header: T("date", lang),
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = moment(row.date).isValid() && moment(row.date).format("HH:mm:ss | DD/MM/YYYY");
				return result;
			}
		},
		{
			accessorKey: "comment",
			header: T("comment", lang),
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = parse(row.desc ? row.desc : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row.name} ${row.name?.ru} ${row.name?.hy}`,
			header: T("name", lang),
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = parse(row.user?.name?.[lang] ? row.user?.name?.[lang] : "No user");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		}
	];

	useEffect(() => {}, [objective]);

	let modelSendToServer = {};

	modelSendToServer = {
		index,
		openedDate,
		closedDate,
		desc,
		ID,
		objective: {
			problem: objective?.problem,
			pc: objective?.pc,
			smb: objective?.smb,
			software: objective?.software,
			room: objective?.room,
			printer: objective?.printer,
			problemRelatedList: objective?.problemRelatedList
		},
		status,
		createdByUser,
		history: History,
		comment,
		importance
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all problems
			let rawProblems = await API.get({
				route: config.api.problem,
				query: {
					parent: null
				}
			});
			if (rawProblems) setProblems(rawProblems);

			// all pcs
			let rawPcs = await API.get({ route: config.api.pc });
			if (rawPcs) setPcs(rawPcs);

			// all server shared folders
			let rawSmbs = await API.get({ route: config.api.smb });
			if (rawSmbs) setsmbs(rawSmbs);

			// all rooms
			let rawRoom = await API.get({ route: config.api.room });
			if (rawRoom) setRooms(rawRoom);

			// all printer
			let rawPrinters = await API.get({ route: config.api.printer });
			let formattedPrinters = [];
			rawPrinters.forEach((printer) => {
				if (printer.room) {
					rawRoom.forEach((room) => {
						if (printer.room === room._id) {
							let newPrinterWithRoom = { ...printer };
							newPrinterWithRoom.room = room;
							newPrinterWithRoom.name = (() => {
								let result = printer.name || {};
								result = "Room " + room?.name + " | " + result;
								return result;
							})();
							formattedPrinters.push(newPrinterWithRoom);
						}
					});
				}
			});
			if (rawPrinters) setPrinters(formattedPrinters);

			// all software
			let rawSoftwares = await API.get({ route: config.api.software });
			if (rawSoftwares) setSoftwares(rawSoftwares);

			// all users
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setUser(rawUsers);
			setITDepartmentUsers(
				rawUsers.filter((user) => user.role?.[0] === "6623b8c3ee7da09567f9979d")
			);

			// all statuses
			let rawStatus = await API.get({
				route: config.api.status,
				sort: {
					index: 1
				}
			});
			const firstSetStatus = rawStatus[0]._id;
			setCloseStatus(rawStatus[rawStatus.length - 1]._id);

			if (rawStatus) {
				rawStatus.shift();
				const statuses = rawStatus.map((item) => item);
				rawStatus.pop();
				const statusWithoutCloses = rawStatus.map((item) => item);
				setStatuses(statuses);
				setStatusWithoutClose(statusWithoutCloses);
			}

			let rawID = await API.get({
				route,
				query: {
					temporary: {
						$ne: true
					}
				},
				sort: {
					ID: -1
				},
				limit: 1
			});

			// select pc automate if there is no pc selected, use user's pc,
			if (objective && objective?.pc && Object.keys(objective?.pc).length === 0) {
				let userPC = rawPcs.find((pc) => pc._id === user.pc);
				if (userPC) {
					setObjective({
						...objective,
						pc: userPC._id,
						room: userPC.room
					});
				}
			}

			if (!tempMode) {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true,
					limit: 1,
					populate: ["createdByUser", "history.user", "comment.user"]
				});

				setID(currentItem[0]?.ID ? currentItem[0]?.ID : rawID[0]?.ID ? rawID[0]?.ID + 1 : 1);
				setIndex(currentItem[0]?.index);
				setDesc(currentItem[0]?.desc);
				setHistory(currentItem[0]?.history);
				setComment(currentItem[0]?.comment);
				setObjective(currentItem[0]?.objective);
				setcreatedByUser(currentItem[0]?.createdByUser);
				setStatus(currentItem[0]?.status);
				currentItem[0]?.openedDate && setOpenedDate(currentItem[0]?.openedDate);
				currentItem[0]?.closedDate && setClosedDate(currentItem[0]?.closedDate);

				setImage(undefined);
				currentItem[0]?.uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					return item;
				});
				if (objective && objective?.pc && Object.keys(objective?.pc).length === 0) {
					let userPC = rawPcs.find((pc) => pc._id === user.pc);
					if (userPC) {
						setObjective({
							...objective,
							pc: userPC._id,
							room: userPC.room
						});
					}
				}
			} else {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true,
					limit: 1,
					populate: ["history.user", "comment.user", "createdByUser"]
				});
				setID(currentItem[0]?.ID ? currentItem[0]?.ID : rawID[0]?.ID ? rawID[0]?.ID + 1 : 1);
				setImage(undefined);
				currentItem[0]?.uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					return item;
				});
				setcreatedByUser(user._id);
				setStatus(firstSetStatus);
				if (objective && objective?.pc && Object.keys(objective?.pc).length === 0) {
					let userPC = rawPcs.find((pc) => pc._id === user.pc);
					if (userPC) {
						setObjective({
							...objective,
							pc: userPC._id,
							room: userPC.room
						});
					}
				}
			}
			setBusy(false);
		})();

		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		// eslint-disable-next-line
	}, [modelSendToServer.history]);

	useEffect(() => {
		if (status) {
			statuses.forEach((item) => {
				if (item._id === status) {
					setStatusForHistory(item);
				}
			});
		}
		// eslint-disable-next-line
	}, [status]);

	useEffect(() => {
		(async () => {
			// get last status id
			let rawStatuses = await API.get({
				route: config.api.status,
				sort: {
					index: "asc"
				}
			});
			if (rawStatuses?.length > 0) {
				setLastStatus(rawStatuses[rawStatuses.length - 2]._id);
				setFirstStatus(rawStatuses[0]._id);
			}
			rawStatuses.forEach((item) => {
				if (item._id === status) {
					setStatusForHistory(item);
				}
			});
		})();
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	const closeTicket = () => {
		modelSendToServer.status = closeStatus;
		modelSendToServer.closedDate = new Date();
		Swal.fire({
			title: T("areYouSure", lang),
			text: T("youCannotRevertThisAction", lang),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: T("yes", lang)
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await UPDATE({
						modelSendToServer,
						route,
						history,
						setBusy,
						id,
						lang,
						Status: statusForHistory
					});
					setBusy(true);
				} catch (error) {
					console.error(error);
				}
			}
		});
	};

	const commentTicket = () => {
		Swal.fire({
			title: T("commentTicket", lang),
			input: "textarea",
			inputPlaceholder: T("commentTicket", lang),
			showCancelButton: true,
			confirmButtonText: T("save", lang),
			cancelButtonText: T("cancel", lang),
			showLoaderOnConfirm: true,
			preConfirm: async (comment) => {
				modelSendToServer.comment = modelSendToServer.comment
					? [...modelSendToServer.comment, { user: user, date: new Date(), desc: comment }]
					: [];
				try {
					await UPDATE({
						modelSendToServer,
						route,
						history,
						setBusy,
						id,
						lang,
						user,
						Status: { name: T("comment", lang) }
					});
					setBusy(true);
				} catch (error) {
					console.error(error);
				}
			},
			allowOutsideClick: () => !Swal.isLoading()
		});
	};

	useEffect(() => {
		if (objective) {
			let state =
				objective?.pc &&
				Object.keys(objective?.pc).length !== 0 &&
				objective?.problem &&
				Object.keys(objective?.problem).length !== 0
					? true
					: false;
			setCanSendState(state);
		}
	}, [objective]);

	useEffect(() => {}, [canSendState]);

	if (isBusy) return <Loading />;
	else if (tempMode)
		return (
			<AddPage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				importance={importance}
				permissionModel="ticket"
				modelSendToServer={modelSendToServer}
				permissions={permissions}
				canSendState={canSendState}
				ITDepartmentUsers={ITDepartmentUsers}
				user={user}
				inputs={[
					{
						value: objective.pc,
						setter: (value) => {
							setObjective({
								pc: value,
								room: pcs.find((pc) => pc._id === value)?.room
							});
						},
						optionListValue: pcs,
						type: "optionList",
						usageType: "optionList",
						label: T("pcs", lang),
						permissionModel: "pc"
					},
					objective.pc
						? {
								setter: (value) => {
									setObjective({
										...objective,
										problem: value,
										problemRelatedList: problems.find((problem) => problem._id === value)
											?.relatedList
									});
								},
								value: objective.problem,
								optionListValue: problems,
								type: "optionList",
								usageType: "optionList",
								label: T("problem", lang),
								permissionModel: "problem"
							}
						: {
								value: objective?.problem,
								type: "optionList",
								usageType: "optionList",
								optionListValue: problems,
								label: T("problem", lang),
								disabled: true,
								permissionModel: "problem"
							},
					objective?.problem && objective.problemRelatedList === "printer"
						? {
								setter: (value) => {
									setObjective({
										...objective,
										printer: value
									});
								},
								value: objective.printer,
								optionListValue: printers,
								type: "optionList",
								usageType: "optionList",
								label: T("printer", lang),
								permissionModel: "printer"
							}
						: null,
					objective?.problem && objective.problemRelatedList === "software"
						? {
								setter: (value) => {
									setObjective({
										...objective,
										software: value
									});
								},
								value: objective.software,
								optionListValue: softwares,
								type: "optionList",
								usageType: "optionList",
								label: T("software", lang),
								permissionModel: "software"
							}
						: null,
					objective?.problem && objective.problemRelatedList === "smb"
						? {
								setter: (value) => {
									setObjective({
										...objective,
										smb: value
									});
								},
								value: objective.smb,
								optionListValue: smbs,
								type: "optionList",
								usageType: "optionList",
								label: T("smb", lang),
								permissionModel: "smb"
							}
						: null,
					{
						value: parse(desc ? desc : ""),
						setter: setDesc,
						width: 12,
						type: "text",
						usageType: "textfield",
						label: T("description", lang),
						permissionModel: "desc"
					},
					{
						value: importance,
						setter: setImportance,
						optionListValue: [
							{
								_id: "low",
								name: {
									en: "Low",
									ru: "Низкий",
									hy: "Ցածր"
								}
							},
							{
								_id: "medium",
								name: {
									en: "Medium",
									ru: "Средний",
									hy: "Միջին"
								}
							},
							{
								_id: "high",
								name: {
									en: "High",
									ru: "Высокий",
									hy: "Բարձր"
								}
							}
						],
						type: "optionList",
						usageType: "optionList",
						label: T("importance", lang),
						permissionModel: "importance"
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						adminMode: user.role !== "editor",
						height: 500,
						placeholder: "Upload image",
						permissionModel: "image"
					}
				]}
			/>
		);
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				chatID={"NQcWJwwsbwuHTeMGJoP8tvaEHgprSB29gf"}
				Status={statusForHistory}
				permissionModel="ticket"
				firstStatus={firstStatus}
				lastStatus={lastStatus}
				modelSendToServer={modelSendToServer}
				sendButtonState={sendButtonState}
				commentTicket={commentTicket}
				closeTicket={closeTicket}
				permissions={permissions}
				inputs={[
					{
						value: objective?.room,
						type: "optionList",
						usageType: "optionList",
						optionListValue: rooms,
						label: T("room", lang),
						disabled: true,
						permissionModel: "room"
					},
					{
						value: ID,
						type: "text",
						usageType: "textfield",
						label: "ID",
						disabled: true,
						permissionModel: "ID"
					},
					{
						value: createdByUser?.name?.[lang] ? createdByUser?.name?.[lang] : "No username",
						type: "text",
						usageType: "textfield",
						label: T("user", lang),
						disabled: true,
						permissionModel: "createdByUser"
					},
					closedDate
						? {
								value: T("closed", lang),
								type: "text",
								usageType: "textfield",
								label: T("status", lang),
								disabled: true,
								permissionModel: "endDate"
							}
						: {
								value: status,
								setter: setStatus,
								optionListValue: statusWithoutClose,
								type: "optionList",
								usageType: "optionList",
								label: T("status", lang),
								permissionModel: "status"
							},
					{
						value: objective?.pc,
						type: "optionList",
						usageType: "optionList",
						optionListValue: pcs,
						label: T("pc", lang),
						disabled: true,
						permissionModel: "pc"
					},
					objective?.problem && {
						value: objective?.problem,
						type: "optionList",
						usageType: "optionList",
						optionListValue: problems,
						label: T("problem", lang),
						disabled: true,
						permissionModel: "problem"
					},
					objective?.printer
						? {
								value: objective?.printer,
								type: "optionList",
								usageType: "optionList",
								optionListValue: printers,
								label: T("printer", lang),
								disabled: true,
								permissionModel: "printer"
							}
						: null,
					objective?.software
						? {
								value: objective?.software,
								type: "optionList",
								usageType: "optionList",
								optionListValue: softwares,
								label: T("software", lang),
								disabled: true,
								permissionModel: "software"
							}
						: null,
					objective?.smb
						? {
								value: objective?.smb,
								type: "optionList",
								usageType: "optionList",
								optionListValue: smbs,
								label: T("smb", lang),
								disabled: true,
								permissionModel: "smb"
							}
						: null,
					{
						value: openedDate,
						// setter: setOpenedDate,
						type: "date",
						disabled: true,
						usageType: "datePicker",
						label: T("openedDate", lang),
						placeholder: T("openedDate", lang),
						permissionModel: "startDate"
					},
					closedDate && {
						value: closedDate,
						setter: setClosedDate,
						disabled: true,
						type: "date",
						usageType: "datePicker",
						label: T("closedDate", lang),
						placeholder: T("selectClosedDate", lang),
						permissionModel: "endDate"
					},
					closedDate && {
						value:
							moment(moment(closedDate) - moment(openedDate)).format("m") +
							` ${T("minutes", lang)}`,
						setter: setClosedDate,
						disabled: true,
						type: "text",
						usageType: "textfield",
						label: T("lasts", lang),
						placeholder: T("selectClosedDate", lang),
						permissionModel: "endDate"
					},
					desc &&
						desc.length > 1 && {
							value: parse(desc ? desc : ""),
							width: 12,
							type: "text",
							usageType: "textfield",
							label: T("description", lang),
							disabled: true,
							permissionModel: "desc"
						}
				]}
				timelineComponent={[
					History &&
						History.length > 0 &&
						Object.keys(History[0]).length > 0 && {
							placeholder: T("history", lang),
							data: History,
							permissionModel: "history"
						},
					comment &&
						comment.length > 0 && {
							placeholder: config.t("commentTicket"),
							data: comment,
							columns: commentColumns,
							permissionModel: "comment"
						}
				]}
				filesComponent={[
					{
						destiny: "image",
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						filesMaxSize: 5,
						acceptedFiles: ["image/png", "image/jpeg", "image/jpg"],
						adminMode: user.role !== "editor",
						height: 500,
						placeholder: "Upload image",
						permissionModel: "image"
					}
				]}
			/>
		);
}
