import React, { useState, useEffect, Suspense } from "react";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mantine-react-table/styles.css"; //import MRT styles
import "@mantine/tiptap/styles.css";
import { Avatar, createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/charts/styles.css";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import { UserContext } from "./helpers/userContext";
import { SocketProvider } from "./helpers/socket";
import API from "./helpers/api";
import { useStateWithLocalStorage } from "./helpers/storage";
import config from "./config";
import Loading from "./components/modules/loading.jsx";
import { Language } from "./helpers/language";
import { BrowserStateProvider, useBrowserState } from "./helpers/browserState";
import { ActivePlatform } from "./helpers/activePlatform.jsx";

// context
import { PermissionContext } from "./helpers/permissionsContext.js";
import { IsMobileProvider } from "./helpers/is-mobile.jsx";

// global components
import Login from "./components/login";
import Main from "./components/main/main";

//Routing
import { Router, Route, Switch } from "react-router-dom";

// Components
import Home from "./components/home";
import Page404 from "./components/Page404";

// project components
import Branch from "./components/project/branch/branch";
import BranchList from "./components/project/branch/branchList";
import Community from "./components/project/community/community";
import CommunityList from "./components/project/community/communityList";
import City from "./components/project/city/city";
import CityList from "./components/project/city/cityList";
import Village from "./components/project/village/village";
import VillageList from "./components/project/village/villageList";
import Voltage from "./components/project/voltage/voltage";
import VoltageList from "./components/project/voltage/voltageList";
import ProjectPage from "./components/project/projectPage";
import ProjectList from "./components/project/projectList";
import ProjectAdd from "./components/project/projectAdd";
import ProjectType from "./components/project/project-type/projectType";
import ProjectTypeList from "./components/project/project-type/projectTypeList";
import ProjectSearch from "./components/project/search/search";

// it components
import Ticket from "./components/it/ticket/ticket";
import Tickets from "./components/it/ticket/tickets";
import PC from "./components/it/pc/pc";
import PCs from "./components/it/pc/pcs";
import RouTer from "./components/it/router/router";
import RouTers from "./components/it/router/routers";
import Switcher from "./components/it/switch/switch";
import Switchs from "./components/it/switch/switchs";
import Room from "./components/it/room/room";
import Rooms from "./components/it/room/rooms";
import Monitor from "./components/it/monitor/monitor";
import Monitors from "./components/it/monitor/monitors";
import Printer from "./components/it/printer/printer";
import Printers from "./components/it/printer/printers";
import OfficeMap from "./components/it/office-map/office_map";
import SMB from "./components/it/smb/smb";
import SMBList from "./components/it/smb/smbList";
import Software from "./components/it/software/software";
import Softwares from "./components/it/software/softwares";
import Problem from "./components/it/problem/problem";
import Problems from "./components/it/problem/problems";
import ItDashboard from "./components/it/it-dashboard/itDashboard";
import Status from "./components/it/status/status";
import Statuses from "./components/it/status/statuses";

// chat components
import Chats from "./components/chat/chats.jsx";
import Chat from "./components/chat/chat.jsx";

// hr components
import UserPage from "./components/hr/user/user";
import Users from "./components/hr/user/users";
import Department from "./components/hr/department/department";
import Departments from "./components/hr/department/departments";
import Position from "./components/hr/position/position";
import Positions from "./components/hr/position/positions";
import Role from "./components/hr/role/role";
import Roles from "./components/hr/role/roles";

import { notifications } from "@mantine/notifications";
import Permissions from "./components/permissions/permissions";

function RouterComponent({ user }) {
	if (user) {
		return (
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path="/home" component={Home} />
					<Route exact path="/ticket" component={Tickets} />
					<Route exact path="/ticket/:id" component={Ticket} />
					<Route exact path="/projects" component={ProjectList} />
					<Route path="/project/edit/" component={ProjectPage} />
					<Route path="/project/add/" component={ProjectAdd} />
					<Route exact path="/branch" component={BranchList} />
					<Route exact path="/branch/:id" component={Branch} />
					<Route exact path="/community" component={CommunityList} />
					<Route exact path="/community/:id" component={Community} />
					<Route exact path="/city" component={CityList} />
					<Route exact path="/city/:id" component={City} />
					<Route exact path="/village" component={VillageList} />
					<Route exact path="/village/:id" component={Village} />
					<Route exact path="/chat" component={Chats} />
					<Route exact path="/chat/:id" component={Chat} />
					<Route exact path="/voltage" component={VoltageList} />
					<Route exact path="/voltage/:id" component={Voltage} />
					<Route exact path="/project-type" component={ProjectTypeList} />
					<Route exact path="/project-search" component={ProjectSearch} />
					<Route exact path="/project-type/:id" component={ProjectType} />
					<Route exact path="/users/:userWorkingStatus?" component={Users} />
					<Route exact path="/user/:id" component={UserPage} />
					<Route exact path="/department" component={Departments} />
					<Route exact path="/department/:id" component={Department} />
					<Route exact path="/position" component={Positions} />
					<Route exact path="/position/:id" component={Position} />
					<Route exact path="/status" component={Statuses} />
					<Route exact path="/status/:id" component={Status} />
					<Route exact path="/pc/" component={PCs} />
					<Route exact path="/pc/:id" component={PC} />
					<Route exact path="/router" component={RouTers} />
					<Route exact path="/router/:id" component={RouTer} />
					<Route exact path="/switch" component={Switchs} />
					<Route exact path="/switch/:id" component={Switcher} />
					<Route exact path="/monitor" component={Monitors} />
					<Route exact path="/monitor/:id" component={Monitor} />
					<Route exact path="/printer" component={Printers} />
					<Route exact path="/printer/:id" component={Printer} />
					<Route exact path="/office-map" component={OfficeMap} />
					<Route exact path="/smb" component={SMBList} />
					<Route exact path="/smb/:id" component={SMB} />
					<Route exact path="/software" component={Softwares} />
					<Route exact path="/software/:id" component={Software} />
					<Route exact path="/problem" component={Problems} />
					<Route exact path="/problem/:id" component={Problem} />
					<Route exact path="/room" component={Rooms} />
					<Route exact path="/room/:id" component={Room} />
					<Route exact path="/role" component={Roles} />
					<Route exact path="/role/:id" component={Role} />
					<Route exact path="/permission" component={Permissions} />
					<Route exact path="/it-dashboard" component={ItDashboard} />
				</Switch>
			</Suspense>
		);
	} else {
		return (
			<Suspense>
				<Route path="*" component={Page404} />
			</Suspense>
		);
	}
}

const theme = createTheme(
	{
		shadows: {
			md: "1px 1px 3px rgba(0, 0, 0, .25)",
			xl: "5px 5px 3px rgba(0, 0, 0, .25)"
		},
		primaryColor: "blue",
		fontFamily: "Roboto, sans-serif"
	},
	{
		links: {
			textDecoration: "none",
			color: "inherit"
		},
		a: {
			textDecoration: "none",
			color: "inherit"
		}
	}
);

function App() {
	let [loggedIn, setLoggedIn] = useState(Boolean);
	let [user, setUser] = useState(Boolean);
	const [permissions, setPermissions] = useState([]);
	let [lang, setLang] = useStateWithLocalStorage("language");
	const [colorScheme, setColorScheme] = useStateWithLocalStorage("mantine-color-scheme-value");
	const [activePlatform, setActivePlatform] = useStateWithLocalStorage("activePlatform");
	const [activePage, setActivePage] = useStateWithLocalStorage("activePage");
	const [subActivePage, setSubActivePage] = useStateWithLocalStorage("subActivePage");

	function logout(socket) {
		setCookies("");
		setLoggedIn(false);
		cookies.remove("authorization");
		if (socket) socket.disconnect();
	}

	function login(username, password) {
		if (username && password) {
			return API.post(config.authentication.login, {
				username,
				password
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(res.data.token);
						setLoggedIn(true);
						setUser(res.data.user);
						setPermissions(res.data.permissions);
					}
				})
				.catch((error) => {
					console.error(error);
					logout();
					return false;
				});
		} else {
			logout();
			console.log("There is no user with provided username & password");
		}
	}

	async function checkToken() {
		let token = await cookies.get(config.authentication.tokenAddress);
		if (token) {
			API.post(config.authentication.verify, {
				token
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(token);
						setLoggedIn(true);
						console.log("🚀 ~ .then ~ res.data.user:", res.data.user);
						setUser(res.data.user);
						setPermissions(res.data.permissions);
					} else {
						setLoggedIn(false);
					}
				})
				.catch((res) => {
					console.error(res);
					console.error(`Please don't edit cookies manually`);
					logout();
				});
		} else {
			logout();
			console.log(
				"You must provide token for calling login -> verifyToken functions, Or mongodb database is not running"
			);
			return false;
		}
	}

	useEffect(() => {
		(async () => {
			if (lang === "" || lang === null) {
				setLang("en");
			}
			if (colorScheme === "" || colorScheme === null) {
				setColorScheme("light");
			}

			if (activePlatform?.length > 0) {
				setActivePlatform(parseFloat(activePlatform));
			} else {
				setActivePlatform(0);
			}

			if (activePage?.length > 0) {
				setActivePage(parseFloat(activePage));
			} else {
				setActivePage(0);
			}

			if (subActivePage?.length > 0) {
				setSubActivePage(subActivePage);
			} else {
				setSubActivePage(0);
			}

			await checkToken();
		})();
	}, []);

	if (loggedIn && user) {
		return (
			<PermissionContext.Provider value={{ permissions, setPermissions }}>
				<Language.Provider value={{ lang, setLang, colorScheme, setColorScheme }}>
					<isLoggedIn.Provider value={{ login, logout, loggedIn }}>
						<UserContext.Provider value={{ user, setUser }}>
							<SocketProvider>
								<ActivePlatform.Provider
									value={{
										activePlatform,
										setActivePlatform,
										activePage,
										setActivePage,
										subActivePage,
										setSubActivePage
									}}
								>
									<BrowserStateProvider>
										<IsMobileProvider>
											<MantineProvider theme={theme} defaultColorScheme="dark">
												<Notifications position="top-right" zIndex={1000} />
												<Router history={history}>
													<Main component={RouterComponent} />
												</Router>
											</MantineProvider>
										</IsMobileProvider>
									</BrowserStateProvider>
								</ActivePlatform.Provider>
							</SocketProvider>
						</UserContext.Provider>
					</isLoggedIn.Provider>
				</Language.Provider>
			</PermissionContext.Provider>
		);
	} else {
		return (
			<Language.Provider value={{ lang, setLang }}>
				<isLoggedIn.Provider value={{ login, logout, loggedIn }}>
					<MantineProvider theme={theme} defaultColorScheme="dark">
						<Notifications position="top-right" zIndex={1000} />
						<Router history={history}>
							<Route component={Login} />
						</Router>
					</MantineProvider>
				</isLoggedIn.Provider>
			</Language.Provider>
		);
	}
}

export default App;
